import React, { createRef } from "react";
import {
  Nav,
  FormInput,
  Card,
  Loader,
  FormCheckbox,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import {
  setNotificationChoice,
  getNotificationChoice,
} from "../../requests/setting";
import { changeInformation, changePassword } from "../../requests/auth";

import Button from "../../components/other/Button";

import AuthContext from "../../context/AuthContext";
export default class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      // password
      isShow: false,
      name: "",
      email: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      isPassword: false,
      update: false,
      // notifications
      not1: false,
    };
    this.signatureRef = createRef();
  }

  static contextType = AuthContext;

  componentDidMount() {
    if (this.context.source !== "agency") {
      this.getNotifications();
    }

    this.setState({
      name: this.context.username,
      email: this.context.email,
    });
  }

  componentDidUpdate() {
    if (this.state.update) {
      this.setState({
        update: false,
      });
      if (
        this.state.oldPassword &&
        this.state.newPassword &&
        this.state.confirmPassword &&
        this.state.newPassword === this.state.confirmPassword
      ) {
        this.setState({
          isPassword: true,
        });
      }
    }
  }

  getNotifications = () => {
    getNotificationChoice((result) => {
      if (result.success) {
        this.setState({ not1: result.data.notification });
      } else {
        this.setState({ not1: false });
      }
    });
  };

  setNotification = (checked) => {
    setNotificationChoice({ notification: checked }, (result) => {
      if (!result.success) {
        alert("Erreur lors de la sauvegarde de vos choix de notification");
        this.setState({ not1: !checked });
      }
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      update: true,
    });
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState(
      {
        [name]: checked,
      },
      () => {
        if (name === "not1") {
          this.setNotification(checked);
        }
      }
    );
  };

  handleChangeInformationClick() {
    if (this.state.name && this.state.email) {
      changeInformation(
        {
          name: this.state.name,
          email: this.state.email,
        },
        (result) => {
          if (result.success) {
            alert("Les informations ont été modifiées");
            document.location.reload();
          } else {
            alert("Une erreur est survenue");
          }
        }
      );
    }
  }

  handleChangePasswordClick() {
    if (this.state.newPassword) {
      changePassword(
        {
          oldPassword: this.state.oldPassword,
          newPassword: this.state.newPassword,
        },
        (result) => {
          if (result.success) {
            alert("Le mot de passe a été modifié");
            document.location.reload();
          } else {
            alert("Une erreur est survenue");
          }
        }
      );
    } else {
      alert("Les deux mots de passes sont différents");
    }
  }

  render() {
    return (
      <>
        <Nav
          className="mb-10 mb-20-xl"
          title={`Paramètres`}
          back
          onBack={this.props.history.goBack}
        />
        <Card title="Informations" className="mb-20">
          <Row>
            <Col sm={12} md={3}>
              <FormInput
                className="mb-20"
                type="text"
                name="name"
                value={this.state.name}
                title="Nom d'utilisateur"
                onChange={this.handleInputChange}
                disabled={this.context.source !== "garage"}
              />
            </Col>
            <Col sm={12} md={3}>
              <FormInput
                className="mb-20"
                type="email"
                name="email"
                value={this.state.email}
                title="Adresse mail"
                onChange={this.handleInputChange}
                disabled={this.context.source !== "garage"}
              />
            </Col>
            <Col sm={12} md={3} className="d-flex">
              <Button
                disabled={
                  this.context.source !== "garage" ||
                  !this.state.name ||
                  !this.state.email
                }
                className="mt-auto mb-20"
                text="Modifier"
                onClick={() => this.handleChangeInformationClick()}
              />
            </Col>
          </Row>
        </Card>
        <Card title="Mot de passe" className="mb-20">
          <Row>
            <Col sm={12} md={2}>
              <FormInput
                className="mb-20"
                type={this.state.isShow ? "text" : "password"}
                name="oldPassword"
                value={this.state.oldPassword}
                title="Ancien mot de passe"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col sm={12} md={3}>
              <FormInput
                className="mb-20"
                type={this.state.isShow ? "text" : "password"}
                name="newPassword"
                value={this.state.newPassword}
                title="Nouveau mot de passe"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col sm={12} md={3}>
              <FormInput
                className="mb-20"
                type={this.state.isShow ? "text" : "password"}
                name="confirmPassword"
                value={this.state.confirmPassword}
                title="Confirmer nouveau mot de passe"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col sm={12} md={2} className="d-flex">
              <Button
                className="mt-auto mb-20"
                text={
                  this.state.isShow
                    ? "Cacher le mot de passe"
                    : "Voir le mot de passe"
                }
                onClick={() => {
                  this.setState({
                    isShow: !this.state.isShow,
                  });
                }}
              />
            </Col>
            <Col sm={12} md={2} className="d-flex">
              <Button
                disabled={!this.state.isPassword}
                className="mt-auto mb-20"
                text="Modifier"
                onClick={() => this.handleChangePasswordClick()}
              />
            </Col>
          </Row>
        </Card>

        {this.context.source !== "agency" ? (
          <Card title="Gestion des notifications" className="mb-20">
            <FormCheckbox
              name="not1"
              checked={this.state.not1}
              onChange={this.handleCheckboxChange}
              text="Notifications par email"
            />
          </Card>
        ) : null}

        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
