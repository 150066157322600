import axiosConfig from "../utils/axiosConfig";

const getFolder = (data, callback) => {
  axiosConfig("folder/get-folder", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const insertMessage = (data, callback) => {
  axiosConfig("folder/insert-message", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getAlias = (data, callback) => {
  axiosConfig("folder/administrative/get-alias", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const resetToken = (data, callback) => {
  axiosConfig("folder/administrative/reset-token", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateData = (data, callback) => {
  axiosConfig("folder/administrative/update-data", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getTimeLine = (data, callback) => {
  axiosConfig("folder/monitoring/get-time-line", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getFiles = (data, callback) => {
  axiosConfig("folder/file/get-files", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const hideFile = (data, callback) => {
  axiosConfig("folder/file/hide-file", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch((e) => {
      callback({ success: false });
    });
};

const getPRE = (data, callback) => {
  axiosConfig("folder/monitoring/get-PRE", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updatePRE = (data, callback) => {
  axiosConfig("folder/monitoring/update-PRE", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getDamageAnalysis = (data, callback) => {
  axiosConfig("folder/monitoring/get-damage-analysis", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getMessage = (data, callback) => {
  axiosConfig("folder/monitoring/get-message", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const disableMessage = (data, callback) => {
  axiosConfig("folder/monitoring/disable-message", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getExpertiseSolution = (data, callback) => {
  axiosConfig("folder/expertise-solution/get", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateExpertiseSolution = (data, callback) => {
  axiosConfig("folder/expertise-solution/update", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateCatnat = (data, callback) => {
  axiosConfig("folder/expertise-solution/update-catnat", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getSelfcareWindtransferExpertiseSolution = (data, callback) => {
  axiosConfig("folder/expertise-solution/get-selfcare-windtransfer", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getVisioconferenceXpertvisioExpertiseSolution = (data, callback) => {
  axiosConfig("folder/expertise-solution/get-visioconference-xpertivisio", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getAvailabilityAgencyExpertiseSolution = (data, callback) => {
  axiosConfig("folder/expertise-solution/get-availability-agency", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const insertSituationIxperience = (data, callback) => {
  axiosConfig("folder/insert-situation-ixperience", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const insertPdfWebservice = (data, callback) => {
  axiosConfig("folder/insert-pdf-webservice", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getElectronicSignature = (data, callback) => {
  axiosConfig("folder/electronic-signature/get-electronic-signature", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateElectronicSignature = (data, callback) => {
  axiosConfig("folder/electronic-signature/update-electronic-signature", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const generateDocument = (data, callback) => {
  axiosConfig("folder/electronic-signature/generate-document", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const generateSignature = (data, callback) => {
  axiosConfig("folder/electronic-signature/generate-signature", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const downloadSignature = (data, callback) => {
  axiosConfig("folder/electronic-signature/download-signature", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getParts = (data, callback) => {
  axiosConfig("folder/parts/get-parts", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getEmailData = (data, callback) => {
  axiosConfig("folder/email/get-email", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getMailevaData = (data, callback) => {
  axiosConfig("folder/email/get-maileva", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const resendEmail = (data, callback) => {
  axiosConfig("folder/email/resend-email", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getWindtransferFolders = (data, callback) => {
  axiosConfig("folder/get-windtransfer-folders", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

export {
  getFolder,
  insertMessage,
  getAlias,
  resetToken,
  updateData,
  getTimeLine,
  getFiles,
  hideFile,
  getPRE,
  updatePRE,
  getDamageAnalysis,
  getMessage,
  disableMessage,
  getExpertiseSolution,
  updateExpertiseSolution,
  updateCatnat,
  getSelfcareWindtransferExpertiseSolution,
  getVisioconferenceXpertvisioExpertiseSolution,
  getAvailabilityAgencyExpertiseSolution,
  insertSituationIxperience,
  insertPdfWebservice,
  getElectronicSignature,
  updateElectronicSignature,
  generateDocument,
  generateSignature,
  downloadSignature,
  getParts,
  getEmailData,
  getMailevaData,
  resendEmail,
  getWindtransferFolders,
};
