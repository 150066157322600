import React from "react";
import {
  Nav,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  Loader,
} from "ui-kit-ck-consultant";
import ReactDOMServer from "react-dom/server";

import Alert from "../../components/folder/alert/Alert";
import Administrative from "../../components/folder/administrative/Administrative";
import HistoriqueEmail from "../../components/folder/historique-email/HistoriqueEmail";
import HistoriqueMaileva from "../../components/folder/historique-email/HistoriqueMaileva";
import Monitoring from "../../components/folder/monitoring/Monitoring";

import ModalMessage from "../../components/folder/ModalMessage";
import ModalContact from "../../components/general/ModalContact";

import {
  getFolder,
  getTimeLine,
  getFiles,
  hideFile,
  getPRE,
  updatePRE,
  getDamageAnalysis,
  getExpertiseSolution,
  updateExpertiseSolution,
  updateCatnat,
  insertSituationIxperience,
  getWindtransferFolders,
} from "../../requests/folder";

import AuthContext from "../../context/AuthContext";

export default class Folder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Tabs
      tabsMenu: [
        "Suivi du dossier",
        "Informations administratives",
        "Historique email(s) / courrier(s)",
      ],
      tabsIndex: 0,
      // Data
      dataTimeLine: [],
      dataExpertiseForm: {},
      dataExpertiseFormPage: [],
      dataExpertiseCatnat: {},
      dataExpertiseCatnatPage: [],
      dataPictures: [],
      dataMessages: [],
      dataDocuments: [],
      dataPRE: null,
      dataDamageAnalysis: {},
      // Data folder
      sinister: {},
      countFolder: 0,
      // Modal message
      isModalMessage: false,
      modalMessageType: "",
      modalMessageTo: "",
      modalMessageCallback: null,
      // Loader
      isLoad: false,
      isSmall: false,
      isAlert: false,
      isLoadMessage: false,
      isLoadDocument: false,
      // Project-E
      projectE: null,
      windtransfer: [],
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getTimeLine();
    this.getExpertiseSolution();
    this.getPRE();
    this.getDamageAnalysis();
    this.getWindtransferFolders();
    this.getFiles();

    this.getSize();
    this.resizeEvent = window.addEventListener("resize", () => {
      this.getSize();
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeEvent);
  }

  getSize = () => {
    let isSmall = window.innerWidth < 1000 ? true : false;
    this.setState({ isSmall });
  };

  getTimeLine = () => {
    getTimeLine({ folderId: this.context.id }, (result) => {
      if (result.success) {
        this.setState({ dataTimeLine: result.data });
      } else {
        this.setState({ dataTimeLine: [] });
      }
    });
  };

  getExpertiseSolution = () => {
    getExpertiseSolution({ folderId: this.context.id }, (result) => {
      if (result.success) {
        this.setState({
          dataExpertiseForm: result.dataExpertiseForm,
          dataExpertiseFormPage: result.dataExpertiseFormPage,
          dataExpertiseCatnat: result.dataExpertiseCatnat,
          dataExpertiseCatnatPage: result.dataExpertiseCatnatPage,
        });
      } else {
        this.setState({
          dataExpertiseForm: {},
          dataExpertiseFormPage: [],
          dataExpertiseCatnat: {},
          dataExpertiseCatnatPage: [],
        });
      }
    });
  };

  updateExpertiseSolution = (
    dataExpertiseForm,
    dataExpertiseFormPage,
    dataAnswer = null
  ) => {
    this.setState(
      {
        dataExpertiseForm,
        dataExpertiseFormPage,
      },
      () => {
        updateExpertiseSolution(
          {
            folderId: this.context.id,
            dataExpertiseForm,
            dataExpertiseFormPage,
            dataAnswer: dataAnswer
              ? ReactDOMServer.renderToStaticMarkup(dataAnswer.getData())
              : "",
          },
          (result) => {
            if (result.success) {
              if (dataExpertiseFormPage.includes(13)) {
                if (dataExpertiseFormPage.includes(10)) {
                  this.insertSituationIxperience(55);
                } else {
                  this.insertSituationIxperience();
                }
              }
            } else {
              alert("Une erreur est survenue");
            }
          }
        );
      }
    );
  };

  updateCatnat = (dataExpertiseCatnat, dataExpertiseCatnatPage, dataAnswer) => {
    this.setState(
      {
        dataExpertiseCatnat,
        dataExpertiseCatnatPage,
      },
      () => {
        updateCatnat(
          {
            folderId: this.context.id,
            dataExpertiseCatnat,
            dataExpertiseCatnatPage,
            dataAnswer: dataAnswer
              ? ReactDOMServer.renderToStaticMarkup(dataAnswer.getData())
              : "",
          },
          (result) => {
            if (result.success) {
              if (dataExpertiseCatnatPage.includes(10)) {
                if (!this.state.dataPRE) {
                  this.updatePRE(
                    dataExpertiseCatnat["PRE_radio"] === 1 ? 1 : 2,
                    dataExpertiseCatnat["PRE_text"],
                    dataExpertiseCatnat["PRE_base64"]
                  );
                }
                this.insertSituationIxperience();
              }
            } else {
              alert("Une erreur est survenue");
            }
          }
        );
      }
    );
  };

  getFiles = () => {
    getFiles(
      { folderId: this.context.id, isLoadMessage: true, isLoadDocument: true },
      (result) => {
        if (result.success) {
          this.setState({
            isLoadMessage: false,
            isLoadDocument: false,
            dataMessages: result.dataMessages || [],
            dataDocuments: result.data.filter(
              (file) => !file.filename.includes("pixtransfer-")
            ),
            dataPictures: result.data.filter(
              (picture) =>
                (["principal", "company", "agency"].includes(
                  this.context.source
                ) ||
                  (picture.source !== "pixtransfer" &&
                    picture.source !== "lacour")) &&
                ["png", "jpg", "jpeg"].includes(picture.extension || "")
            ),
          });
        }
      }
    );
  };

  hideFile = (token) => {
    hideFile({ folderId: this.context.id, token }, (result) => {
      if (result.success) {
        const dataDocuments = this.state.dataDocuments.filter(
          (element) => element.token !== token
        );
        const dataPictures = this.state.dataPictures.filter(
          (element) => element.token !== token
        );

        this.setState({ dataDocuments, dataPictures });
      } else {
        alert("Une erreur est survenue");
      }
    });
  };

  getPRE = () => {
    getPRE({ folderId: this.context.id }, (result) => {
      if (result.success) {
        this.setState({ dataPRE: result.data });
      } else {
        this.setState({ dataPRE: null });
      }
    });
  };

  updatePRE = (value, name, signature) => {
    updatePRE(
      { folderId: this.context.id, value, name, signature },
      (result) => {
        if (result.success) {
          this.setState({
            dataPRE: value,
          });
        } else {
          alert("Une erreur est survenue");
        }
      }
    );
  };

  getDamageAnalysis = () => {
    getDamageAnalysis({ folderId: this.context.id }, (result) => {
      if (result.success) {
        this.setState({ dataDamageAnalysis: result.data });
      } else {
        this.setState({ dataDamageAnalysis: {} });
      }
    });
  };

  handleOpenModalMessage = (messageType = "", to = "", callback = () => {}) => {
    this.setState({
      isModalMessage: true,
      modalMessageType: messageType,
      modalMessageTo: to,
      modalMessageCallback: callback,
    });
  };

  insertSituationIxperience = (codeId = 83) => {
    this.setState({ isLoad: true }, () => {
      insertSituationIxperience(
        { folderId: this.context.id, codeId },
        (result) => {
          if (result.success) {
            this.getFolder();
          } else {
            this.setState({ isLoad: false });
            alert("Une erreur est survenue");
          }
        }
      );
    });
  };

  getFolder = () => {
    if (this.props.match.params.token) {
      getFolder(
        { folderToken: this.props.match.params.token },
        (resultFolder) => {
          if (resultFolder.success) {
            const dataFolder = resultFolder.data;
            this.setState({
              sinister: dataFolder.sinister,
              countFolder: dataFolder.countFolder,
              isLoad: false,
            });
          }
        }
      );
    }
  };

  getTitle = () => {
    if (this.context.source.includes("agency")) {
      return "";
    } else if (this.context.source.includes("company")) {
      return "";
    } else if (this.context.source.includes("principal")) {
      return "";
    } else if (this.context.source.includes("garage")) {
      return "";
    } else if (this.context.source.includes("client")) {
      return `Bonjour ${this.context.client.name}`;
    }
  };

  getSubtitle = () => {
    if (this.context.source.includes("client")) {
      return `Bienvenue sur le suivi de votre dossier de sinistre`;
    } else {
      return "Bienvenue sur le suivi du dossier";
    }
  };

  readMessage = (dataMessages, idx) => {
    if (this.context.source === "client") {
      dataMessages[idx].readClient = 1;
    } else if (this.context.source === "garage") {
      dataMessages[idx].readGarage = 1;
    }

    this.setState({ dataMessages });
  };

  readDocument = (idx) => {
    let dataDocuments = this.state.dataDocuments;

    if (this.context.source === "client") {
      dataDocuments[idx].readClient = 1;
    } else if (this.context.source === "garage") {
      dataDocuments[idx].readGarage = 1;
    }

    this.setState({ dataDocuments });
  };

  getWindtransferFolders = () => {
    getWindtransferFolders(
      { reference: this.context.sinister.reference },
      (result) => {
        if (result.success) {
          this.setState({
            windtransfer: result.folders,
          });
        }
      }
    );
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          ...this.context,
          handleOpenModalMessage: this.handleOpenModalMessage,
          projectE: this.state.projectE,
          windtransfer: this.state.windtransfer,
          dataDocuments: this.state.dataDocuments,
          hideFile: this.hideFile,
        }}
      >
        <Nav
          title={this.getTitle()}
          subtitle={this.getSubtitle()}
          back={
            (this.context.countFolder || this.state.countFolder) <= 1 &&
            this.context.source === "client"
              ? false
              : true
          }
          onBack={() =>
            this.props.history.push("/home", this.props.location.state)
          }
          className="mb-10 mb-20-xl"
        >
          <Alert
            ref={(ref) => {
              this.alertRef = ref;
            }}
            openExpertiseSolution={
              this.monitoringRef && this.monitoringRef.expertiseSolutionRef
                ? this.monitoringRef.expertiseSolutionRef.openExpertiseSolution
                : () => {}
            }
            openPopup={() => this.setState({ isAlert: true })}
            onClosePopup={() => this.setState({ isAlert: false })}
            isAlert={this.state.isAlert}
            count={0}
            statusId={this.context.sinister.statusId}
            dataTimeLine={this.state.dataTimeLine}
            insertSituationIxperience={this.insertSituationIxperience}
          />
        </Nav>
        <TabsCustom>
          <TabsCustomHeader>
            {this.state.tabsMenu.map((menu, index) => {
              if (
                (this.context.source !== "agency") &
                (menu === "Historique email")
              )
                return null;

              return (
                <TabsCustomMenu
                  key={index}
                  title={menu}
                  active={this.state.tabsIndex === index}
                  onClick={() => this.setState({ tabsIndex: index })}
                />
              );
            })}
          </TabsCustomHeader>
          <TabsCustomBody noCard>
            <TabsCustomItem active={this.state.tabsIndex === 0}>
              <Monitoring
                onCloseAlert={() => this.setState({ isAlert: false })}
                isAlert={this.state.isAlert}
                match={this.props.match}
                ref={(ref) => {
                  this.monitoringRef = ref;
                }}
                sinister={this.state.sinister}
                getFiles={this.getFiles}
                getFolder={this.getFolder}
                dataTimeLine={this.state.dataTimeLine}
                dataExpertiseForm={this.state.dataExpertiseForm}
                dataExpertiseFormPage={this.state.dataExpertiseFormPage}
                dataExpertiseCatnat={this.state.dataExpertiseCatnat}
                dataExpertiseCatnatPage={this.state.dataExpertiseCatnatPage}
                dataPictures={this.state.dataPictures}
                dataPRE={this.state.dataPRE}
                dataDamageAnalysis={this.state.dataDamageAnalysis}
                dataMessages={this.state.dataMessages}
                updateExpertiseSolution={this.updateExpertiseSolution}
                updateCatnat={this.updateCatnat}
                updatePRE={this.updatePRE}
                insertSituationIxperience={this.insertSituationIxperience}
                isSmall={this.state.isSmall}
                readMessage={this.readMessage}
                readDocument={this.readDocument}
                isLoadMessage={this.state.isLoadMessage}
                isLoadDocument={this.state.isLoadDocument}
                alertRef={this.alertRef}
              />
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 1}>
              <Administrative />
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 2}>
              <HistoriqueEmail />
              <HistoriqueMaileva />
            </TabsCustomItem>
          </TabsCustomBody>
        </TabsCustom>
        <ModalMessage
          show={this.state.isModalMessage}
          onClose={(isReload) =>
            this.setState(
              {
                isModalMessage: false,
                modalMessageType: "",
                modalMessageTo: "",
                modalMessageCallback: null,
              },
              () => {
                if (isReload) {
                  this.getFiles();
                }
              }
            )
          }
          messageType={this.state.modalMessageType}
          to={this.state.modalMessageTo}
          callback={this.state.modalMessageCallback}
        />
        <Loader show={this.state.isLoad} />
        <ModalContact onClick={() => this.handleOpenModalMessage()} />
      </AuthContext.Provider>
    );
  }
}
