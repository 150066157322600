const data = [
  {
    id: "expert",
    name: "Interlocuteur technique",
    data: [
      [
        {
          id: "name",
          name: "Nom",
        },
        {
          id: "phone",
          name: "Téléphone",
        },
        {
          id: "email",
          name: "E-mail",
        },
      ],
    ],
    xs: 12,
    lg: 4,
  },
  {
    id: "agency",
    name: "Agence",
    data: [
      [
        {
          id: "name",
          name: "Nom",
        },
        {
          id: "phone",
          name: "Téléphone",
          isPhone: true,
        },
        {
          id: "email",
          name: "E-mail",
        },
      ],
      [
        {
          id: "address1",
          name: "Adresse",
        },
        {
          id: "zipCode",
          name: "Code postal",
        },
        {
          id: "city",
          name: "Ville",
        },
      ],
    ],
    xs: 12,
    lg: 8,
  },
  {
    isEdit: true,
    id: "garage",
    name: "Réparateur",
    data: [
      [
        {
          id: "name",
          name: "Nom",
        },
        {
          id: "phone",
          name: "Téléphone",
        },
        {
          id: "email",
          name: "E-mail",
        },
      ],
      [
        {
          id: "address1",
          name: "Adresse",
        },
        {
          id: "zipCode",
          name: "Code postal",
        },
        {
          id: "city",
          name: "Ville",
        },
      ],
    ],
    xs: 12,
    lg: 6,
  },
  {
    isEdit: true,
    id: "principal",
    name: "Mandant",
    data: [
      [
        {
          id: "name",
          name: "Nom",
        },
      ],
      [
        {
          id: "address1",
          name: "Adresse",
        },
        {
          id: "zipCode",
          name: "Code postal",
        },
        {
          id: "city",
          name: "Ville",
        },
      ],
    ],
    xs: 12,
    lg: 6,
  },
  {
    id: "sinister",
    name: "Sinistre",
    data: [
      [
        {
          id: "sinister",
          name: "Numéro de sinistre",
        },
        {
          id: "natureEvent",
          name: "Nature évènement",
        },
        {
          id: "natureExpertise",
          name: "Nature expertise",
        },
      ],
    ],
    xs: 12,
    lg: 4,
  },
  {
    isEdit: true,
    id: "vehicle",
    name: "Véhicule",
    data: [
      [
        {
          id: "brand",
          name: "Marque",
        },
        {
          id: "model",
          name: "Modèle",
        },
        {
          id: "finish",
          name: "Finition",
        },
        {
          id: "licensePlate",
          name: "Immatriculation",
        },
        {
          id: "firstRegistration",
          name: "1ère mise en circulation",
        },
        {
          id: "registrationCertificate",
          name: "Date certificat d'immatriculation",
        },
        {
          id: "technicalControl",
          name: "Date validité contrôle technique",
        },
        {
          id: "VIN",
          name: "VIN",
        },
      ],
    ],
    xs: 12,
    lg: 4,
  },
  {
    isEdit: true,
    id: "client",
    name: "Client",
    data: [
      [
        {
          id: "name",
          name: "Nom",
        },
        {
          id: "address1",
          name: "Adresse",
        },
        {
          id: "address2",
          name: "Complément d'adresse",
        },
        {
          id: "zipCode",
          name: "Code postal",
        },
        {
          id: "city",
          name: "Ville",
        },
        {
          id: "email",
          name: "E-mail",
        },
        {
          id: "phone",
          name: "Téléphone",
        },
        {
          id: "cellphone",
          name: "Portable",
        },
      ],
    ],
    xs: 12,
    lg: 4,
  },
];

module.exports = data;
