import React from "react";
import { FormInput, Loader } from "ui-kit-ck-consultant";

import logo from "../../images/sigle-ixperience.svg";

import Button from "../../components/other/Button";

import { forgotCheckToken, savePasswordForgot } from "../../requests/auth";

export default class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      email: "",
      isShow: false,
      password: "",
      confirmPassword: "",
      type: null,
      isError: false,
      isLoad: false,
    };
  }

  componentDidMount() {
    const token = this.props.match.params.token;

    this.setState({ isLoad: true }, () => {
      forgotCheckToken({ token }, (result) => {
        if (result.success) {
          this.setState({
            isLoad: false,
            id: result.data.id,
            email: result.data.email,
            type: result.data.type,
          });
        } else {
          this.setState({ isLoad: false }, () => {
            alert("Lien de réinitialisation non valide.");
            document.location.href = "/forgot";
          });
        }
      });
    });
  }

  handleNewPasswordClick = (event) => {
    event.preventDefault();
    const token = this.props.match.params.token;

    if (
      this.state.password &&
      this.state.confirmPassword &&
      this.state.password === this.state.confirmPassword
    ) {
      this.setState({ isLoad: true });
      savePasswordForgot(
        {
          id: this.state.id,
          type: this.state.type,
          password: this.state.password,
          token,
        },
        (result) => {
          if (result.success) {
            this.setState({ isLoad: false }, () => {
              alert("Mot de passe modifié avec succès");
              document.location.href = "/signin";
            });
          } else {
            this.setState({ isLoad: false }, () => {
              alert(
                "Erreur lors de la réinitialisation du mot de passe. Veuillez réessayer."
              );
            });
          }
        }
      );
    } else {
      this.setState({ isError: true });
    }
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      isError: false,
    });
  };

  render() {
    return (
      <div>
        <img
          alt="logo"
          className="d-block ml-auto mr-auto mb-20"
          style={{ maxWidth: "100%" }}
          height="60"
          src={logo}
        />

        <h4 className="pt-20 text-center">Nouveau mot de passe</h4>

        <FormInput
          className="mb-20 pt-20"
          title="Adresse email"
          name="email"
          type="text"
          value={this.state.email}
          onChange={this.handleInputChange}
          required
          disabled
        />

        <FormInput
          className="mb-20"
          title="Nouveau mot de passe"
          placeholder="*********"
          name="password"
          type={this.state.isShow ? "text" : "password"}
          value={this.state.password}
          onChange={this.handleInputChange}
          required
          error={
            this.state.isError && !this.state.password
              ? "Veuillez entrer un nouveau mot de passe"
              : ""
          }
        />

        <FormInput
          className="mb-20"
          title="Répéter le mot de passe"
          placeholder="*********"
          name="confirmPassword"
          type={this.state.isShow ? "text" : "password"}
          value={this.state.confirmPassword}
          onChange={this.handleInputChange}
          required
          error={
            this.state.isError &&
            this.state.password &&
            !this.state.confirmPassword
              ? "Veuillez confirmer le mot de passe"
              : this.state.isError &&
                this.state.password &&
                this.state.confirmPassword &&
                this.state.password !== this.state.confirmPassword
              ? "Les mots de passe sont différents"
              : ""
          }
        />

        <Button
          className="mb-20"
          text={
            this.state.isShow
              ? "Cacher le mot de passe"
              : "Voir le mot de passe"
          }
          onClick={() => {
            this.setState({
              isShow: !this.state.isShow,
            });
          }}
        />
        <Button
          className="mb-20"
          text="Valider"
          onClick={this.handleNewPasswordClick}
        />

        <Loader show={this.state.isLoad} />
      </div>
    );
  }
}
