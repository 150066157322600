import React from "react";
import { ButtonIcon, FormInput, FormSelect } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import logo from "../../images/ixperience1.svg";
import logoLangResize from "../../images/logo-lang-resize.png";

import { signin } from "../../requests/auth";

import Button from "../../components/other/Button";
import ModalContact from "../../components/general/ModalContact";

export default class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.location.state
        ? this.props.location.state.username
        : "",
      password: "",
      type: "",
      isShow: false,
      isMultiple: false,
      isError: false,
    };
    this.cookies = new Cookies();
  }

  handleSignin = (event) => {
    event.preventDefault();
    if (
      this.cookies.get("ixperience-cookies") === "1" ||
      this.cookies.get("ixperience-cookies") === "2"
    ) {
      if (this.state.username && this.state.password) {
        signin(
          {
            username: this.state.username,
            password: this.state.password,
            type: this.state.type,
          },
          (result) => {
            if (result.success) {
              const redirection = this.props.match.params.redirection;
              const message = this.props.match.params.message;
              if (redirection) {
                document.location.href = `/folder/${redirection}${
                  message ? `/${message}` : ""
                }`;
              } else {
                document.location.reload();
              }
            } else {
              this.setState({
                isError: true,
                isMultiple: this.state.isMultiple || result.isMultiple,
              });
            }
          }
        );
      } else {
        this.setState({ isError: true });
      }
    } else {
      alert(
        "Vous devez accepter l'utilisation des cookies pour accéder au site."
      );
    }
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      isError: false,
      ...(name === "username" ? { isMultiple: false, type: "" } : {}),
    });
  };

  render() {
    return (
      <>
        <Row className="w-100 overflow-y-auto">
          {window.innerWidth > 540 ? (
            <Col xs={12} md={6} className="d-flex">
              <div className="background-test">
                <img
                  src={logo}
                  alt="logo ixperience"
                  style={{ maxWidth: "100%", maxHeight: "36px" }}
                />
                <h3>
                  Bienvenue sur le portail dédié au suivi de votre dossier de
                  sinistre.
                </h3>
                <img
                  height={60}
                  alt="groupe lang & associés"
                  src={logoLangResize}
                />
                <p>
                  Espace personnel qui vous permet de suivre en temps réel
                  l'évolution du traitement de votre dossier et recevoir des
                  informations ou des documents de la part de votre expert.
                </p>
                <p>
                  Vous pouvez également prendre rendez-vous avec nos services,
                  nous contacter par messagerie ou nous faire parvenir des
                  documents..
                </p>
              </div>
            </Col>
          ) : (
            <Col xs={12} md={6} className="d-flex">
              <div className="mt-auto ml-auto mr-auto text-center">
                <img
                  src={logo}
                  alt="logo ixperience"
                  style={{ maxWidth: "100%", height: "36px" }}
                />
                <h4>
                  Bienvenue sur le portail dédié au suivi de votre dossier de
                  sinistre.
                </h4>
                <img
                  height={60}
                  alt="groupe lang & associés"
                  src={logoLangResize}
                />
              </div>
            </Col>
          )}
          <Col xs={12} md={6} className="mt-20 mb-20 d-flex">
            <div
              className="m-auto bg-white"
              style={{
                borderRadius: "15px",
                padding: "40px",
              }}
            >
              <h2 className="m-0 mb-10">Connexion</h2>
              <FormInput
                className="mb-10"
                title="Adresse E-mail ou Identifiant"
                placeholder="01200124"
                name="username"
                type="text"
                value={this.state.username}
                onChange={this.handleInputChange}
                required
              />

              <div className="d-flex mb-10" style={{ gap: 10 }}>
                <FormInput
                  className="w-100"
                  title="Mot de passe"
                  placeholder="*********"
                  name="password"
                  type={this.state.isShow ? "text" : "password"}
                  value={this.state.password}
                  onChange={this.handleInputChange}
                  required
                  error={
                    this.state.isError && !this.state.isMultiple
                      ? "Identifiant ou mot de passe incorrects"
                      : ""
                  }
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      this.handleSignin(e);
                    }
                  }}
                />
                <ButtonIcon
                  className="mt-auto"
                  isBackground
                  onClick={() => {
                    this.setState({
                      isShow: !this.state.isShow,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={this.state.isShow ? faEyeSlash : faEye}
                  />
                </ButtonIcon>
              </div>
              {this.state.isMultiple ? (
                <FormSelect
                  className="mb-10"
                  name={"type"}
                  value={this.state.type}
                  onChange={this.handleInputChange}
                  title={"Type de compte"}
                  required
                  error={
                    "Veuillez sélectionner le type de compte que vous souhaitez utiliser"
                  }
                  options={[
                    {
                      value: "DEFAULT",
                      text: "Veuillez sélectionner un type de compte",
                      disabled: true,
                    },
                    {
                      value: "client",
                      text: "Assuré",
                    },
                    {
                      value: "garage",
                      text: "Réparateur",
                    },
                    {
                      value: "agency",
                      text: "Expert",
                    },
                    {
                      value: "company",
                      text: "Assureur",
                    },
                    {
                      value: "principal",
                      text: "Mandant",
                    },
                  ]}
                />
              ) : null}

              <div className="d-flex mt-20">
                <Button
                  className="mb-20 w-auto ml-auto"
                  text="Se connecter"
                  onClick={this.handleSignin}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
              <Link
                to={{ pathname: "/forgot" }}
                style={{ color: "var(--primary-color)" }}
              >
                Mot de passe oublié ?
              </Link>
            </div>
          </Col>
        </Row>

        <ModalContact />
      </>
    );
  }
}
