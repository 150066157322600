import React from "react";
import { FormInput, Loader } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import Button from "../../components/other/Button";
import ModalContact from "../../components/general/ModalContact";

import logo from "../../images/ixperience1.svg";
import logoLangResize from "../../images/logo-lang-resize.png";

import {
  checkTokenNewAccount,
  savePasswordNewAccount,
  signin,
} from "../../requests/auth";

export default class NewAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      isShow: false,
      email: "",
      password: "",
      confirmPassword: "",
      type: null,
      isError: false,
      isLoad: false,
    };
  }

  componentDidMount() {
    const token = this.props.match.params.token;
    const type = this.props.match.params.type;

    this.setState({ isLoad: true }, () => {
      checkTokenNewAccount({ token, type }, this.checkTokenResult);
    });
  }

  checkTokenResult = (result) => {
    if (result.success) {
      this.setState({
        isLoad: false,
        id: result.data.id,
        email: result.data.email,
      });
    } else {
      this.setState({ isLoad: false }, () => {
        const redirection = this.props.match.params.redirection;
        const message = this.props.match.params.message;
        if (redirection) {
          if (result.success) {
            document.location.href = `/folder/${redirection}${
              message ? `/${message}` : ""
            }`;
          } else {
            document.location.href = `/signin/${redirection}${
              message ? `/${message}` : ""
            }`;
          }
        } else {
          document.location.href = "/signin";
        }
      });
    }
  };

  handleNewPasswordClick = (event) => {
    event.preventDefault();
    if (
      this.state.password &&
      this.state.confirmPassword &&
      this.state.password === this.state.confirmPassword
    ) {
      this.setState({ isLoad: true }, () => {
        const token = this.props.match.params.token;

        savePasswordNewAccount(
          {
            id: this.state.id,
            type: this.props.match.params.type,
            password: this.state.password,
            token,
          },
          (result) => {
            if (result.success) {
              this.setState({ isLoad: false });
              alert("Mot de passe modifié avec succès");

              // Sign in the user
              signin(
                { username: this.state.email, password: this.state.password },
                (result) => {
                  if (result.success) {
                    const redirection = this.props.match.params.redirection;
                    const message = this.props.match.params.message;
                    if (redirection) {
                      document.location.href = `/folder/${redirection}${
                        message ? `/${message}` : ""
                      }`;
                    } else {
                      document.location.href = "/signin";
                    }
                  } else {
                    document.location.href = "/signin";
                  }
                }
              );
            } else {
              this.setState({ isLoad: false });
              alert("Le lien n'est plus valide, veuillez-vous reconnecter.");
            }
          }
        );
      });
    } else {
      this.setState({ isError: true });
    }
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      isError: false,
    });
  };

  render() {
    return (
      <>
        <Row className="w-100 overflow-y-auto">
          {window.innerWidth > 540 ? (
            <Col xs={12} md={6} className="d-flex">
              <div className="background-test">
                <img
                  src={logo}
                  alt="logo ixperience"
                  style={{ maxWidth: "100%", maxHeight: "36px" }}
                />
                <h2>
                  Bienvenue sur le portail dédié au suivi de votre dossier
                </h2>
                <img
                  height={60}
                  alt="groupe lang & associés"
                  src={logoLangResize}
                />
                <p
                  className="bg-red-light p-20 m-0"
                  style={{ borderRadius: "10px" }}
                >
                  Pour votre première connexion et afin de garantir votre
                  sécurité, nous vous invitons à personnaliser votre mot de
                  passe.
                </p>
              </div>
            </Col>
          ) : (
            <Col xs={12} md={6} className="d-flex">
              <div className="mt-auto ml-auto mr-auto text-center">
                <img
                  src={logo}
                  alt="logo ixperience"
                  style={{ maxWidth: "100%", height: "36px" }}
                />
                <h4>
                  Bienvenue sur le portail dédié au suivi de votre dossier de
                  sinistre.
                </h4>
                <img
                  height={60}
                  alt="groupe lang & associés"
                  src={logoLangResize}
                />
              </div>
            </Col>
          )}
          <Col xs={12} md={6} className="pl-20 mt-20 mb-20 d-flex">
            <div
              className="m-auto bg-white"
              style={{
                borderRadius: "15px",
                padding: "40px",
              }}
            >
              <h2 className="m-0 mb-10">Création du mot de passe</h2>
              <p className="bg-red-light p-20" style={{ borderRadius: "10px" }}>
                Veuillez initialiser votre mot de passe.
              </p>
              <FormInput
                className="mb-20 pt-20"
                title="Adresse email"
                name="email"
                type="text"
                value={this.state.email}
                onChange={this.handleInputChange}
                required
                disabled
              />

              <FormInput
                className="mb-20"
                title="Nouveau mot de passe"
                placeholder="*********"
                name="password"
                type={this.state.isShow ? "text" : "password"}
                value={this.state.password}
                onChange={this.handleInputChange}
                required
                error={
                  this.state.isError && !this.state.password
                    ? "Veuillez entrer un mot de passe"
                    : ""
                }
              />

              <FormInput
                className="mb-20"
                title="Répéter le mot de passe"
                placeholder="*********"
                name="confirmPassword"
                type={this.state.isShow ? "text" : "password"}
                value={this.state.confirmPassword}
                onChange={this.handleInputChange}
                required
                error={
                  this.state.isError &&
                  this.state.password &&
                  !this.state.confirmPassword
                    ? "Veuillez confirmer le mot de passe"
                    : this.state.isError &&
                      this.state.password &&
                      this.state.confirmPassword &&
                      this.state.password !== this.state.confirmPassword
                    ? "Les mots de passe sont différents"
                    : ""
                }
              />

              <div className="d-flex">
                <Button
                  className="mb-20"
                  text={
                    this.state.isShow
                      ? "Cacher le mot de passe"
                      : "Voir le mot de passe"
                  }
                  onClick={() => {
                    this.setState({
                      isShow: !this.state.isShow,
                    });
                  }}
                />
              </div>
              <div className="d-flex">
                <Button
                  className="mb-20 w-auto ml-auto"
                  text="Valider"
                  onClick={this.handleNewPasswordClick}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>

              <Loader show={this.state.isLoad} />
            </div>
          </Col>
          {window.innerWidth > 992 ? (
            <Col xs={12} lg={6} className="d-flex">
              <div style={{ margin: "auto", maxWidth: "430px" }}>
                <Row>
                  <Col xs={12} className="text-center"></Col>
                </Row>
              </div>
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <ModalContact />
      </>
    );
  }
}
